<template>
  <div>
    <div class="h-[100vw] flex justify-center items-center text-center">
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')

    if (code) {
      if (window.opener) {
        window.opener.postMessage({ code }, '*')
      }
      localStorage.setItem('google_oauth_code', JSON.stringify({ code }))
      window.close()
    }
  },
}
</script>
